import React, { useState, useEffect, useRef } from 'react';
import { Bell, LogOut, Upload, Link2, X, CheckCircle2, LogIn, FileUp, AlertCircle, Loader2 } from 'lucide-react';
import axios from 'axios';
import logo from "../logo.png";
import LoginModal from './LoginModal';


const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const bgColor = type === 'success' ? 'bg-green-500' : type === 'error' ? 'bg-red-500' : 'bg-blue-500';

  return (
    <div className={`fixed top-4 right-4 z-50 flex items-center p-4 ${bgColor} text-white rounded-lg shadow-lg transform transition-all duration-500 animate-slide-in`}>
      {type === 'success' ? (
        <CheckCircle2 className="w-5 h-5 mr-2" />
      ) : type === 'error' ? (
        <AlertCircle className="w-5 h-5 mr-2" />
      ) : (
        <Bell className="w-5 h-5 mr-2" />
      )}
      <span className="mr-4">{message}</span>
      <button onClick={onClose} className="ml-2 hover:opacity-80">
        <X className="w-4 h-4" />
      </button>
    </div>
  );
};

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);

  const fileInputRef = useRef(null);
  const countdownTimer = useRef(null);

  useEffect(() => {
    if (isLocked && countdown > 0) {
      countdownTimer.current = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(countdownTimer.current);
    } else if (countdown === 0) {
      setIsLocked(false);
    }
  }, [isLocked, countdown]);

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
  };

  const handleDragEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    handleDragEvents(e);
    setIsDragging(false);

    if (!isLocked) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setFile(droppedFile);
        setError('');
      }
    }
  };
  const handleFileChange = (event) => {
    if (!isLocked) {
      const selectedFile = event.target.files?.[0];
      if (selectedFile) {
        setFile(selectedFile);
        setError('');
      }
    }
  };

  const handleUpload = async () => {
    if (!file || isLocked || isUploading) return;

    setIsUploading(true);
    setError('');
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Is-Logged-In': isLoggedIn.toString()
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const fileLink = `${process.env.REACT_APP_API_BASEURL}/file/${response.data.fileId}/${encodeURIComponent(response.data.originalName)}`;
      setLink(fileLink);
      setFile(null);
      setIsLocked(true);
      setCountdown(30);
      showNotification('File uploaded successfully!', 'success');
    } catch (err) {
      setError('Upload failed. Please try again.');
      showNotification('Upload failed. Please try again.', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopySuccess(true);
      showNotification('Link copied to clipboard!', 'success');
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy link');
      showNotification('Failed to copy link', 'error');
    }
  };
    const handleLogin = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/login`, {
          username,
          password
        });

        if (response.data.success) {
          setIsLoggedIn(true);
          setShowLoginModal(false);
          setUsername('');
          setPassword('');
          showNotification('Successfully logged in', 'success');
        }
      } catch (err) {
        setError('Invalid credentials');
        showNotification('Invalid credentials', 'error');
      }
    };
    const handleLogout = () => {
      setIsLoggedIn(false);
      setUsername('');
      setPassword('');
      showNotification('Successfully logged out', 'success');
    };  

    return (
      <div className="min-h-screen p-6 bg-gradient-to-br from-blue-50 to-gray-100">
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="flex items-center justify-between mb-8">
            <img src={logo} alt="Logo" className="w-auto h-12" />
            <div className="flex items-center gap-4">
              {isLoggedIn ? (
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">Welcome, {username}</span>
                  <button
                    onClick={handleLogout}
                    className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Logout
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setShowLoginModal(true)}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
                >
                  <LogIn className="w-4 h-4 mr-2" />
                  Login
                </button>
              )}
            </div>
          </div>

          {/* Main Content */}
          <div className="p-8 bg-white shadow-xl rounded-2xl">
            <h1 className="mb-8 text-3xl font-bold text-center text-gray-800">
              Secure File Upload
            </h1>

            {/* Upload Area */}
            <div
              className={`relative border-2 border-dashed rounded-xl transition-all duration-200 
              ${isDragging ? 'border-blue-500 bg-blue-50' : isLocked ? 'border-gray-300 bg-gray-50' : 'border-gray-300 hover:border-blue-400'}
              p-8 text-center`}
              onDragEnter={(e) => {
                handleDragEvents(e);
                if (!isLocked) setIsDragging(true);
              }}
              onDragLeave={(e) => {
                handleDragEvents(e);
                setIsDragging(false);
              }}
              onDragOver={handleDragEvents}
              onDrop={handleDrop}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                disabled={isLocked}
              />

              <div className="flex flex-col items-center justify-center space-y-4">
                <FileUp 
                onClick={() => fileInputRef.current?.click()}
                className={`w-16 h-16 ${isLocked ? 'text-gray-400' : 'text-blue-500'}`} />

                {isLocked ? (
                  <div className="flex items-center space-x-2 text-red-500">
                    <AlertCircle className="w-5 h-5" />
                    <span>Upload locked for {countdown}s</span>
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="text-lg font-medium text-gray-700 transition-colors hover:text-blue-600"
                      disabled={isLocked}
                    >
                      Click to upload or drag and drop
                    </button>
                    <p className="text-sm text-gray-500">
                      Upload one file at a time
                    </p>
                  </>
                )}

                {file && (
                  <div className="flex items-center p-3 mt-4 rounded-lg bg-gray-50">
                    <FileUp className="w-5 h-5 mr-2 text-gray-500" />
                    <span className="text-sm text-gray-600">{file.name}</span>
                    <button
                      onClick={() => setFile(null)}
                      className="p-1 ml-2 transition-colors rounded-full hover:bg-gray-200"
                    >
                      <X className="w-4 h-4 text-gray-500" />
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Upload Button */}
            <button
              onClick={handleUpload}
              disabled={!file || isLocked || isUploading}
              className="flex items-center justify-center w-full px-6 py-3 mt-6 text-white transition-all duration-200 bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isUploading ? (
                <>
                  <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                  Uploading...
                </>
              ) : (
                <>
                  <Upload className="w-5 h-5 mr-2" />
                  Upload File
                </>
              )}
            </button>

            {/* Progress Bar */}
            {isUploading && (
              <div className="mt-4">
                <div className="h-2 overflow-hidden bg-gray-200 rounded-full">
                  <div
                    className="h-full transition-all duration-300 bg-blue-500"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
                <p className="mt-2 text-sm text-center text-gray-600">
                  {uploadProgress}% uploaded
                </p>
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="p-4 mt-4 border border-red-200 rounded-lg bg-red-50">
                <div className="flex items-center text-red-700">
                  <AlertCircle className="w-5 h-5 mr-2" />
                  <span>{error}</span>
                </div>
              </div>
            )}

            {/* Success Message with Link */}
            {link && (
              <div className="p-4 mt-6 border border-green-200 rounded-lg bg-green-50">
                <div className="flex items-center mb-2">
                  <CheckCircle2 className="w-5 h-5 mr-2 text-green-500" />
                  <p className="font-medium text-green-700">File uploaded successfully!</p>
                </div>

                {!isLoggedIn && (
                  <p className="mb-2 text-sm text-gray-600">
                    Your file link (valid for 3 days):
                  </p>
                )}

                <div className="flex items-center gap-2 mt-2">
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-blue-600 break-all hover:text-blue-700"
                  >
                    {link}
                  </a>
                  <button
                    onClick={copyToClipboard}
                    className="flex items-center px-3 py-2 text-sm font-medium text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
                  >
                    <Link2 className="w-4 h-4 mr-2" />
                    {copySuccess ? 'Copied!' : 'Copy Link'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>



    {/* Login Modal */}
    {showLoginModal && (
        <LoginModal
          onClose={() => setShowLoginModal(false)}
          onLogin={handleLogin}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
        />
      )}
    </div>
  );
};

export default FileUpload;